@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.loadingContainer {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.loading {
  width: 14px;
  height: 14px;
  background: conic-gradient(
    from 90deg at 50% 50%,
    rgba(255, 255, 255, 0.0001) 0deg,
    #275ce5 360deg
  );
  opacity: 0.5;
  border-radius: 50%;
  position: relative;
  animation: spin 0.6s linear infinite;

  &::before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    border-radius: 50%;
    background-color: #fff;
    top: 2px;
    left: 2px;
    display: inline-block;
  }
}
