.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2.5em;
  border: 1px solid #ededed;
  background: #fff;
  box-shadow: 0 4px 50px rgba(0, 0, 0, 5%);
  border-radius: 8px;
  overflow: hidden;
}
