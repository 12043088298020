.title {
  display: flex;
  align-items: center;
  min-height: 50vh;
  color: #fff;
  background-color: #030a34;
  padding-top: 3em;
  padding-left: 2em;
  padding-right: 2em;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 520px;

  & div {
    max-width: 1080px;
    width: 100%;
    margin: auto;
  }
}

.content {
  background-color: #fff;
  max-width: 1080px;
  min-height: 45vh;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 4em;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #fff;
  overflow: hidden;
}

.footer {
  color: #fff;
  padding: 2em;
  text-align: center;
  background-color: #040c41;

  span:not(:first-of-type) {
    margin-left: 3em;
  }
}

.container {
  display: flex;
}

@media screen and (max-width: 800px) {
  .title {
    padding: 2em;
  }
}
