.title {
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
  font-size: 28px;

  & > header {
    font-weight: bold;
    letter-spacing: 0.1em;
  }
  & > span {
    letter-spacing: 0.05em;
    & > span {
      color: #e3a83e;
    }
  }
}

.contentContainer {
  display: grid;
  gap: 4em;
}

.about {
  color: #808080;
  font-size: 16px;
  line-height: 1.5;
}

.cultureItem {
  margin: 1em;
  display: grid;
  gap: 0.75em;
  justify-items: center;
  text-align: center;
  line-height: 1.6;

  & svg {
    color: #c85b39;
    font-size: 80px;
  }

  & > section {
    color: #595959;
  }
}

.aboutUs {
  margin-bottom: 3em;
  & > header {
    font-size: 18px;
    text-align: center;
    margin-bottom: 1em;
  }

  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4.5em;
  }

  & p {
    color: #595959;
    line-height: 2;
    margin-bottom: 1em;
    margin-top: 0;
    font-size: 1.2em;
  }
  & img {
    border-radius: 8px;
  }
}

.product {
  & p {
    color: #595959;
    line-height: 2;
    margin-bottom: 1em;
    margin-top: 0;
    font-size: 1em;
  }
  & img {
    border-radius: 8px;
  }

  & > div:first-of-type {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
    text-align: center;

    & > h3 {
      margin: 0.25em;
    }
  }
}
.productContent > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  text-align: start;
}

.coreTeamItem {
  line-height: 2;
  text-align: center;
  font-size: 1.125em;

  & > header {
    margin-top: 0.25em;
  }

  & > span {
    color: #004cff;
  }
  & > section {
    font-size: 0.9em;
    color: #595959;
    margin-top: 0.25em;
  }
}
.dots {
  & > li {
    background: #e0eaff4f;
  }
  & > li[class^='slick-active'] button {
    background: rgba(65, 65, 98, 0.475) !important;
  }
}

.joinUsItem {
  padding-top: 2em;
  text-align: center;

  img {
    border-radius: 8px;
  }
  header {
    font-size: 1.2em;
    margin: 0.6em;
    & > span {
      margin-left: 0.5em;
    }
  }
  & > span {
    color: #595959;
  }
}
.joinUsImg {
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  margin-top: 3em;
  line-height: 1.8;
  padding: 3em;

  & > div {
    color: #fff;
    text-align: start;
  }
}

@media screen and (max-width: 800px) {
  .aboutUs {
    & > div {
      grid-template-columns: auto;
      grid-template-rows: 1fr auto;
      gap: 0;
    }
  }

  .productContent > div {
    grid-template-columns: auto;
    grid-template-rows: 1fr auto;
    gap: 2em;
  }
}
