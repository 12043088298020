body,
html,
#root {
  margin: 0;
}

/* 去掉header高度 使之覆盖背景 */
.ant-pro-layout-container > .ant-layout-header.css-ggwo7z:first-of-type {
  display: none;
}

/* menu 字色覆盖 */
.ant-pro-top-nav-header-base-menu {
  .ant-pro-base-menu-item-title {
    line-height: 1.8;
  }
  .ant-menu-item-active {
    color: #dfe0e4 !important;
  }

  .ant-pro-base-menu-item-title {
    color: #fff !important;
  }
}

.ant-pro-global-header-right-content {
  color: #dfe0e4 !important;
}

.ant-pro-top-nav-header-logo h1 {
  font-size: 0.9em !important;
  color: #fff !important;
}

header.ant-layout-header.css-dev-only-do-not-override-ggwo7z {
  position: absolute;
}

/** 覆盖高德logo */
.amap-logo {
  display: none !important;
}

.amap-copyright {
  display: none !important;
}

/** Join us 图片中心按钮样式 **/
#join-us {
  .ant-btn.ant-btn-default.ant-btn-lg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 4px;
    border-color: #fff;

    span {
      font-size: 0.9em;
      padding: 1em 2em;
      color: #004cff;
    }
  }
}

/** 小屏菜单折叠成的折叠面板定位为fixed 否则下滑时会出现样式错位 **/
.ant-drawer .ant-drawer-mask,
.ant-drawer .ant-drawer-content-wrapper {
  position: fixed;
}

/** 微调menu的竖直位置，使之与icon文字对齐 **/
.ant-pro-base-menu-item-text {
  margin-bottom: -0.2em;
}
