:global {
  #root {
    height: 100%;
  }

  .ant-pro-global-header-logo img {
    height: 44px;
  }
}

.headerButton {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: rgba(0, 0, 0, 2.5%);
  }
}
