.item {
  width: 100%;
  color: #a9a3a3;

  & > header {
    color: #263050;
    font-weight: bold;
    font-size: 1.25em;
    margin-bottom: 0.2em;
  }
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.container {
  margin-bottom: 10em;
  display: grid;
  gap: 4em;
}
