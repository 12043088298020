.container {
  padding-top: 0.3em;
  border-bottom: 2px solid #f0f0f0;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.container > span {
  border-left: 2px #004cff solid;
  color: #141414;
  padding-left: 0.5em;
  line-height: 1;
  margin-bottom: 0.5em;
}
