.link {
  cursor: pointer;
  color: #006eff;
}

.ant-pro-layout .ant-layout-header.ant-pro-layout-header {
  transition: all 0.2s;
}

.ant-pro-global-header-collapsed-button {
  color: #fff;
}
