.title {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  max-width: 1080px;
  text-align: start;
  line-height: 1.8;

  & > p {
    font-size: 1.7em;
    align-self: center;

    & > strong {
      font-weight: normal;
      color: #e3a83e;
    }
  }

  & > section {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5em;
  }
}

.contentContainer {
  display: grid;
}

.checkbox {
  & > label {
    margin-inline-start: 0 !important;
  }
}

.content {
  & > h5 {
    margin-top: 0;
    margin-bottom: 1em;
    margin-left: 0.2em;
  }
}

.detailTitle {
  display: grid;
  justify-content: center;
  gap: 0.2em;

  & > span:first-of-type {
    font-weight: bold;
    font-size: 2.5em;
  }

  & > span:last-of-type {
    color: #cecece;
    font-size: 1.5em;
  }
}

.infoContainer {
  display: flex;
  flex-direction: column;
  margin-top: -2.5em;

  & > section {
    margin-bottom: 1.5em;
  }
}

.detailSection {
  line-height: 2;
  color: #858585;
  font-size: 1.1em;
}

.optionsContainer {
  margin-right: 2em;

  & > h5[class^='ant-typography'] {
    margin-top: 0 !important;
  }
}

.notificationInfo {
  line-height: 1.8;
  font-size: 1.1em;
  color: #434343;
}

@media screen and (max-width: 768px) {
  .contentContainer {
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 3em;

    & > button {
      grid-row: 1 / span 1;
    }
  }
}

@media screen and (min-width: 768px) {
  .contentContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 3em;
  }
}
